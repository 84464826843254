import { once } from 'lodash-es'

const analytics = fakeAnalytics()
type Rudder = typeof import('rudder-sdk-js')

export const { page, track, identify, alias, group, reset, page: pageView } = analytics

const init = once(async () => {
  const rudder = await import('rudder-sdk-js')

  rudder.load('2GgwWxixE7YvbLsKfU39H6DBZ56', 'https://airheartijidy.dataplane.rudderstack.com', {
    sendAdblockPage: true,
  })

  // rudder.ready(() => {})

  return rudder
})

export async function rudderInitialize() {
  return init()
}

function fakeAnalytics(): Rudder {
  return {
    page: (url: string) => null,
    track: (event: any) => null,
    identify: () => null,
    alias: () => null,
    group: () => null,
    reset: () => null,
    pageView: (url: string) => null,
  } as unknown as Rudder
}
